<template>
  <el-container>
    <el-main>
      <el-breadcrumb separator="/">
        <el-breadcrumb-item>在线制版平台</el-breadcrumb-item>
        <el-breadcrumb-item>业务数据</el-breadcrumb-item>
        <el-breadcrumb-item>发行版格式</el-breadcrumb-item>
      </el-breadcrumb>
      <SearchForm v-if="searchOptions.open" v-model="searchOptions.searchForm" :items="searchOptions.items" @search="handleSearch" :params="params">
        <el-button v-if="editOptions.open && !selector && g_getUser() && g_getUser().isRoot" type="primary" @click="editFormVisible = true">添加数据</el-button>
      </SearchForm>
      <template v-if="tableOptions.open">
        <el-table :data="tableOptions.tableData" stripe border size="small"
                  header-cell-class-name="table_header"
                  cell-class-name="table_cell" style="width: 100%">
          <el-table-column type="index" label="序号" width="50"/>
          <template v-if="tableOptions.items">
            <template v-for="item in tableOptions.items">
              <el-table-column v-if="item.type === Search_Type.Select" :label="item.label">
                <template slot-scope="scope">
                  <template v-if="item.props">
                    <template v-if="item.props[scope.row[item.name]]">
                      <template v-if="item.propsColors && item.propsColors[scope.row[item.name]]">
                        <el-tag :type="item.propsColors[scope.row[item.name]]" disable-transitions>
                          {{item.props[scope.row[item.name]]}}
                        </el-tag>
                      </template>
                      <template v-else>
                        {{item.props[scope.row[item.name]]}}
                      </template>
                    </template>
                    <template v-else-if="scope.row[item.name]">
                      <el-tag type="danger" disable-transitions>
                        {{scope.row[item.name]}}
                      </el-tag>
                    </template>
                    <template v-else>
                      {{scope.row[item.name]}}
                    </template>
                  </template>
                  <template v-else>
                    <el-tag type="warning" disable-transitions>
                      {{scope.row[item.name]}}
                    </el-tag>
                  </template>
                </template>
              </el-table-column>
              <el-table-column v-else-if="item.type === Search_Type.DynamicSelect" :label="item.label">
                <template slot-scope="scope">
                  <el-tag :type="g_getDynamicValue(scope.row[item.name],item, true)"
                          disable-transitions>{{g_getDynamicValue(scope.row[item.name],item)}}</el-tag>
                </template>
              </el-table-column>
              <el-table-column v-else :prop="item.name" :label="item.label"/>
            </template>
          </template>
          <el-table-column min-width="200" label="操作">
            <template slot-scope="scope">
              <template v-if="!selector">
<!--                <el-button type="text" @click="openMenu(scope.row.id)">-->
<!--                  子菜单 <i class="el-icon-school"></i>-->
<!--                </el-button>-->
<!--                <el-divider direction="vertical"></el-divider>-->
                <template v-if="editOptions.open">
                  <el-button type="text" @click="editHandle(scope.row.id)">
                    修改 <i class="el-icon-edit"></i>
                  </el-button>
                  <el-divider direction="vertical"></el-divider>
                </template>
                <template v-if="detailOptions.open">
                  <el-button type="text" @click="openDetail(scope.row)">
                    详细 <i class="el-icon-document"></i>
                  </el-button>
                  <el-divider direction="vertical"></el-divider>
                </template>
<!--                <el-button v-if="scope.row.status === 0" type="text" @click="enableHandle(scope.row.id)">-->
<!--                  启用 <i class="el-icon-open"></i>-->
<!--                </el-button>-->
<!--                <el-button v-if="scope.row.status === 1" type="text" @click="disableHandle(scope.row.id)">-->
<!--                  停用 <i class="el-icon-turn-off"></i>-->
<!--                </el-button>-->
              </template>
              <template v-else>
                <el-button type="text" @click="selectHandle(scope.row)">
                  选择 <i class="el-icon-check"></i>
                </el-button>
              </template>
            </template>
          </el-table-column>
        </el-table>
        <el-pagination
            @size-change="handleSizeChange"
            @current-change="handleCurrentChange"
            layout="total, sizes, prev, pager, next, jumper"
            :page-sizes="[10, 20, 50, 100]"
            :current-page="tableOptions.page"
            :page-size="tableOptions.limit"
            :total="tableOptions.total">
        </el-pagination>
      </template>
      <!--popup search-->
      <el-dialog title="子菜单" :visible.sync="menuVisible" center width="70%" append-to-body :before-close="closeMenu">
        <Menu2_DcpVer :params="searchOptions.searchParams"></Menu2_DcpVer>
      </el-dialog>
      <el-dialog :title="editOptions.id?'修改数据':'添加数据'" :visible.sync="editFormVisible" width="85%" center append-to-body :before-close="closeEditForm">
        <EditForm v-if="editOptions.open" v-model="editOptions" :id="editOptions.id" @close="closeEditForm"></EditForm>
      </el-dialog>
      <el-dialog :visible.sync="detailPanelVisible" center width="70%" append-to-body :before-close="closeDetailPanel">
        <DetailPanel v-if="detailOptions.open" v-model="detailOptions" :id="detailOptions.id" title="详细信息"></DetailPanel>
      </el-dialog>
      <el-dialog :title="qrCodeTitle" :visible.sync="qrCodeVisible" append-to-body destroy-on-close center :before-close="closeQrCode">
        <div style="margin:0 auto;display: flex;flex-direction: column;align-items: center;gap: 15px;">
          <BlobImageScrollable :url="url" :fileName="qrCodeImgName" :width="$store.getters.barcodeWidth" :height="$store.getters.barcodeHeight"></BlobImageScrollable>
        </div>
      </el-dialog>
    </el-main>
  </el-container>
</template>

<script>
import SearchForm from "../common/OptionalSearchForm";
import Menu2_DcpVer from "./Menu2_DcpVer"
import EditForm from "../common/EditForm";
import DetailPanel from "../common/DetailPanel";
import BlobImageScrollable from "../common/BlobImageScrollable";
import {Search_Type} from "../../constants";
const prefix = '/dcpver';
const getListUrl = `${prefix}/list`;
const getOneUrl = (id) => `${prefix}/one/${id}`
const getDetailUrl = (id) => `${prefix}/detail/${id}`
const postUrl = `${prefix}/save`;
const putUrl = (id) => `${prefix}/update/${id}`
const enableUrl = (id)=>`${prefix}/enable/${id}`
const disableUrl = (id)=>`${prefix}/disable/${id}`
export default {
  name: 'Menu2_DcpVer',
  components: {SearchForm,Menu2_DcpVer,EditForm,DetailPanel,BlobImageScrollable},
  data(){
    return{
      Search_Type,
      tableOptions:{
        tableData:[],
        page: 1,
        limit: 10,
        total: 0,
      },
      searchOptions:{
        searchForm: {},
        searchParams:{},
      },
      editOptions: {
        postUrl: postUrl,
        getOneUrl: getOneUrl,
        putUrl: putUrl,
      },
      detailOptions: {
        getDetailUrl : getDetailUrl,
        initData: null,
      },
      editFormVisible: false,
      menuVisible: false,
      detailPanelVisible: false,
      qrCodeVisible: false,
      qrCodeUrl: (id,width,height)=> `${this.g_getRequestBaseUrl()}${prefix}/qrcode/${id}?width=${width}&height=${height}`,
      url: null,
      qrCodeTitle: '',
      qrCodeImgName: '',
    }
  },
  props:['params','selector'],
  created() {
    this.g_setupMetadataConfig(this, this.$options.name)
    if(!this.params){
      if(this.tableOptions.items.some(item=>item.type === Search_Type.DynamicSelect)){
        this.loadDynamicSelectData()
      }else{
        this.getTableData();
      }
    }
  },
  methods: {
    loadDynamicSelectData(){
      let needLoad = true;
      //加载动态键值对
      this.tableOptions.items.filter(item=>item.type === Search_Type.DynamicSelect).forEach(item=>{
        let dynamicUrl = item.dynamicUrl;
        if(!dynamicUrl || item.props) return;
        needLoad = false
        this.$axios.get(dynamicUrl).then(res=>{
          try {
            let params = item.dynamicParams.split(',')
            if(params.length !== 2) throw 'error parameter num'
            item.p1 = params[0]
            item.p2 = params[1]
            item.props = res.data
          }catch (e) {
            console.log(e)
          }
          this.getTableData()
        })
      })
      if(needLoad) this.getTableData()
    },
    getTableData(){
      this.$axios.get(getListUrl,{
        params: {
          ...this.g_getPage(this.tableOptions.page,this.tableOptions.limit),
          ...this.searchOptions.searchForm
        }
      }).then(res=>{
        this.tableOptions.tableData = res.data.records;
        this.tableOptions.page = res.data.current;
        this.tableOptions.limit = res.data.size;
        this.tableOptions.total = res.data.total;
      })
    },
    handleSizeChange(limit){
      this.tableOptions.limit = limit;
      this.getTableData();
    },
    handleCurrentChange(page){
      this.tableOptions.page = page;
      this.getTableData();
    },
    handleSearch(){
      this.tableOptions.page = 1;
      this.getTableData();
    },
    enableHandle(id){
      this.$axios.put(enableUrl(id)).then(()=>{
        this.$message({
          showClose: true,
          message: `恭喜你，操作成功`,
          type: 'success',
          duration: 1500,
          onClose:() => {
            this.getTableData();
          }
        });
      })
    },
    disableHandle(id){
      this.$axios.put(disableUrl(id)).then(()=>{
        this.$message({
          showClose: true,
          message: `恭喜你，操作成功`,
          type: 'success',
          duration: 1500,
          onClose:() => {
            this.getTableData();
          }
        });
      })
    },
    closeMenu(done){
      this.searchOptions.searchParams = []
      done();
    },
    openMenu(id){
      this.menuVisible = true;
      this.searchOptions.searchParams = [{name:'id',value:id}];
    },
    openDetail(row){
      // this.detailOptions.id = id;
      this.detailOptions.initData = row
      this.detailPanelVisible = true
    },
    editHandle(id){
      this.editOptions.id = id
      this.editFormVisible = true
    },
    closeEditForm(result){
      this.editOptions.id = null
      this.editFormVisible = false
      if(result){
        this.getTableData()
      }
    },
    closeDetailPanel(){
      this.detailOptions.id = null
      this.detailPanelVisible = false
    },
    selectHandle(row){
      this.$emit('onselect', row)
    },
    openQrCodeHandle(row){
      this.qrCodeVisible = true
      this.url = this.qrCodeUrl(row.id, this.$store.getters.barcodeWidth , this.$store.getters.barcodeHeight)
      this.qrCodeTitle = '(编码标准)'+ row.vcodec
      this.qrCodeImgName = row.vcodec+".png"
    },
    closeQrCode(){
      this.url = ''
      this.qrCodeVisible = false
    },
  }
}
</script>

<style scoped>

</style>